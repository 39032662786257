import { NavLink, Outlet } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import { Routes } from "../../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import "./Profil.css";
import { UserTypeEnum } from "../../utils";

function ProfilLayout() {
  const { user } = useAuth();

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-3" id="main-profil">
          <div className="text-center profil-image" style={{ backgroundImage: "url(img/user.webp" }}></div>
          <div className="mt-4">
            <p className="fw-bold fs-4 lh-sm">{user?.username}</p>
            <p className="fs-5">{user?.email}</p>
            <span className="badge bg-secondary text-black fs-6 mt-2">{user?.userType === UserTypeEnum.Teacher ? "Compte Enseignant" : "Compte Parent"}</span>
          </div>
        </div>
        <div className="col-12 col-md-9">
          <div className="card">
            <div className="card-header bg-ternary">
              <ul className="nav nav-tabs card-header-tabs" style={{ marginRight: "-1rem" }}>
                <li className="nav-item">
                  <NavLink to={Routes.PROFIL_PARAMETERS} className="nav-link">
                    <FontAwesomeIcon icon={faGear} />
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilLayout;
