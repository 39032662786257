import { createSearchParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "../contexts/auth";
import { LinearProgress } from "@mui/material";
import { Routes } from "../routes";

type ProtectedRouteProps = {
  needAdmin?: boolean;
  needSuperAdmin?: boolean;
};

export default function ProtectedRoute({ children }: React.PropsWithChildren<ProtectedRouteProps>) {
  const { user, isInitialLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isInitialLoading && !user) {
      navigate({
        pathname: Routes.LOGIN,
        search: createSearchParams({
          redirect: window.location.href,
        }).toString(),
      });
    }
  }, [user, isInitialLoading, navigate]);

  let canConnect: boolean = !!user;

  return canConnect ? children : <LinearProgress className="m-auto" style={{ maxWidth: 960 }} />;
}
