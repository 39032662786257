import { useForm } from "react-hook-form";
import { Checkbox, FormButtonLoading } from "../components";
import { api, ApiError } from "../hooks";
import { useMutation } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faRightFromBracket, faUser } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../contexts/auth";
import { Link, useNavigate } from "react-router-dom";
import { Routes } from "../routes";
import { DefaultErrorsType, MutationData } from "../types";
import { Alert } from "react-bootstrap";
import { UserTypeEnum } from "../utils";

type FormValues = {
  email: string;
  username: string;
  agreeTerms: boolean;
  passwordFirst: string;
  passwordSecond: string;
  userType: UserTypeEnum | null;
};

const defaultValues: FormValues = {
  email: "",
  username: "",
  agreeTerms: false,
  passwordFirst: "",
  passwordSecond: "",
  userType: null,
};

const defaultErrors: DefaultErrorsType<FormValues> = {
  email: {
    required: "Veuillez saisir un email.",
    maxLength: { value: 255, message: "L'email doit contenir moins de 255 caratères." },
  },
  username: {
    required: "Veuillez saisir un pseudo.",
    maxLength: { value: 16, message: "Le pseudo doit contenir moins de 16 caratères." },
  },
  agreeTerms: {
    validate: (value) => value === true || "Vous devez accepter les termes.",
  },
  passwordFirst: {
    required: "Veuillez saisir votre mot de passe.",
    minLength: { value: 6, message: "Le mot de passe doit contenir au moins 6 caractères." },
  },
  passwordSecond: {
    required: "Veuillez confirmer votre mot de passe.",
    validate: (value, formValues) => value === formValues.passwordFirst || "Les deux mots de passe doivent être identiques.",
  },
  userType: {
    validate: (value) => value !== null || "Veuillez choisir le type de compte",
  },
};

function Register() {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
  });

  const registerMutation = useMutation<MutationData, ApiError<FormValues>, FormValues>({
    mutationFn: (data) => {
      return api.user.register(data);
    },
    onSuccess: (data) => {
      reset();
      navigate({ pathname: Routes.LOGIN, search: "variant=success&message=" + data.message });
    },
    onError: (data) => {
      data.formErrors.forEach(({ name, message }) => {
        setError(name, { message: message });
      });
    },
  });

  const onSubmit = (data: FormValues) => {
    registerMutation.mutate(data);
  };

  return (
    <div className="form-template mx-auto">
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="label-form">
          <h1 className="h3 text-center">S'inscrire</h1>
        </div>
        <div className="alert-form">
          {registerMutation.isError && registerMutation.error.formErrors.length === 0 && <Alert variant={registerMutation.error.variant}>{registerMutation.error.message}</Alert>}
          {registerMutation.isSuccess && <Alert variant="success">{registerMutation.data.message}</Alert>}

          {errors.root && <Alert variant="danger">{errors.root.message}</Alert>}

          {user && (
            <div className="mb-3">
              Vous êtes connecté en tant que {user.email},{" "}
              <Link to={Routes.HOME} onClick={logout}>
                se déconnecter.
              </Link>
            </div>
          )}
        </div>
        <div className="body-form">
          <div className="mb-3 input-group">
            <span className="input-group-text">
              <FontAwesomeIcon icon={faEnvelope} />
            </span>
            <div className="form-floating">
              <input type="text" {...register("email", defaultErrors.email)} id="inputEmail" className={"form-control" + (errors.email ? " is-invalid" : "")} placeholder="Email" required autoFocus />
              <label htmlFor="inputEmail" className="form-label">
                Email
              </label>
            </div>
            {errors.email && <div className="invalid-feedback d-block">{errors.email.message}</div>}
          </div>
          <div className="mb-3 input-group">
            <span className="input-group-text">
              <FontAwesomeIcon icon={faUser} />
            </span>
            <div className="form-floating">
              <input type="text" {...register("username", defaultErrors.username)} id="inputPseudo" className={"form-control" + (errors.username ? " is-invalid" : "")} placeholder="Pseudo" required />
              <label htmlFor="inputPseudo" className="form-label">
                Pseudo
              </label>
            </div>
            {errors.username && <div className="invalid-feedback d-block">{errors.username.message}</div>}
          </div>
          <div className="mb-3 input-group">
            <span className="input-group-text">
              <FontAwesomeIcon icon={faLock} />
            </span>
            <div className="form-floating">
              <input
                type="password"
                {...register("passwordFirst", defaultErrors.passwordFirst)}
                id="current-password-first"
                className={"form-control" + (errors.passwordFirst ? " is-invalid" : "")}
                placeholder="Mot de passe"
                autoComplete="current-password"
                required
              />
              <label htmlFor="current-password-first" className="form-label">
                Mot de passe
              </label>
            </div>
            {errors.passwordFirst && <div className="invalid-feedback d-block">{errors.passwordFirst.message}</div>}
          </div>
          <div className="mb-3 input-group">
            <span className="input-group-text">
              <FontAwesomeIcon icon={faLock} />
            </span>
            <div className="form-floating">
              <input
                type="password"
                {...register("passwordSecond", defaultErrors.passwordSecond)}
                id="current-password-second"
                className={"form-control" + (errors.passwordSecond ? " is-invalid" : "")}
                placeholder="Confirmer le mot de passe"
                autoComplete="current-password"
                required
              />
              <label htmlFor="current-password-second" className="form-label">
                Confirmer le mot de passe
              </label>
            </div>
            {errors.passwordSecond && <div className="invalid-feedback d-block">{errors.passwordSecond.message}</div>}
          </div>
          <div className="mb-3 text-center">
            <div className="text-center">
              <Checkbox label="Compte Enseignant" name="userType" value={UserTypeEnum.Teacher} register={register} error={errors.userType} defaultErrors={defaultErrors.userType} />
              <Checkbox label="Compte Parent" name="userType" value={UserTypeEnum.Parent} register={register} error={errors.userType} defaultErrors={defaultErrors.userType} />
            </div>
            {errors.userType && <div className="invalid-feedback d-block">{errors.userType.message}</div>}
          </div>
          <div className="mb-3 form-check checkbox">
            <input type="checkbox" {...register("agreeTerms", defaultErrors.agreeTerms)} className={"form-check-input" + (errors.agreeTerms ? " is-invalid" : "")} id="agree_terms" />
            <label className="form-check-label" htmlFor="agree_terms">
              Accepter les{" "}
              <Link to={Routes.CGU} target="_blank" rel="noreferrer">
                CGU
              </Link>
            </label>
            {errors.agreeTerms && <div className="invalid-feedback d-block">{errors.agreeTerms.message}</div>}
          </div>

          <div className="btn-form">
            <FormButtonLoading type="submit" isPending={registerMutation.isPending} label="S'inscrire" className="btn btn-primary" />
          </div>
          <div className="text-end">
            <Link to={Routes.LOGIN} className="link-primary text-decoration-none">
              J'ai déjà un compte <FontAwesomeIcon icon={faRightFromBracket} />
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Register;
