import axios from "axios";
import { getGetAxiosInstance, handleError } from "./utils";

class HomeAPI {
  async stats() {
    try {
      const response = await getGetAxiosInstance().get("/stats");
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }
}

export default HomeAPI;
