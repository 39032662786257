import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api, ApiError, useUtils } from "../hooks";
import { MutationData, RitualParametersFormValue, TextProblemType } from "../types";
import { Dice } from "../components";
import { useAuth } from "../contexts/auth";
import { useModals } from "../contexts/modals";
import { getOperators, ModalTypeEnum, SchoolZoneEnum, TextProblemTypeEnum } from "../utils";
import { useEffect, useMemo, useRef, useState } from "react";
import { Alert } from "react-bootstrap";
import { CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faInfoCircle, faMinus, faPlus, faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Routes } from "../routes";
import { RituelKey } from "./query";

function Rituel() {
  const { user } = useAuth();
  const { addBoldToQuestion, sanitize } = useUtils();
  const { openModal, closeModal, isModalOpen } = useModals();
  const queryClient = useQueryClient();

  const [showProblems, setShowProblems] = useState(false);

  const [showNivel, setShowNivel] = useState<boolean | null>(true);
  const [showType, setShowType] = useState(true);
  const [textSize, setTextSize] = useState(12);

  const [rituelParameters, setRituelParameters] = useState<RitualParametersFormValue>({ schoolZone: null, classNivel: [] });

  const isRitualParametersCorrect = useMemo(() => {
    if ((user && user.classNivel.length !== 0 && user.schoolZone !== null) || (rituelParameters.classNivel.length !== 0 && rituelParameters.schoolZone !== null)) {
      if (user && (rituelParameters.classNivel.length === 0 || rituelParameters.schoolZone === null)) {
        setRituelParameters({ schoolZone: user.schoolZone, classNivel: user.classNivel });
      }
      return true;
    } else {
      return false;
    }
  }, [user, rituelParameters]);

  const { isLoading, isError, error, data, refetch } = useQuery<{ problems: TextProblemType[]; canSeePublicProblems: boolean }, ApiError>({
    queryKey: RituelKey(rituelParameters.schoolZone as SchoolZoneEnum, rituelParameters.classNivel),
    queryFn: () => api.rituel.get(rituelParameters.schoolZone as SchoolZoneEnum, rituelParameters.classNivel),
    enabled: isRitualParametersCorrect,
  });

  const refetchRituelProblem = useMutation<MutationData & { problem: TextProblemType }, ApiError, { nivel: string }>({
    mutationFn: (data) => {
      return api.rituel.refetch(rituelParameters.schoolZone as SchoolZoneEnum, data.nivel);
    },
    onSuccess: (data) => {
      queryClient.setQueryData<{ problems: TextProblemType[]; canSeePublicProblems: boolean }>(RituelKey(rituelParameters.schoolZone as SchoolZoneEnum, rituelParameters.classNivel), (old) =>
        old
          ? {
              ...old,
              problems: [...old.problems.map((problem) => (problem.nivel === data.problem.nivel ? data.problem : problem))],
            }
          : old
      );
    },
  });

  useEffect(() => {
    if (!isRitualParametersCorrect) {
      openModal(ModalTypeEnum.RituelParameters, { setParameters: setRituelParameters });
    } else if (isModalOpen(ModalTypeEnum.RituelParameters)) {
      closeModal(ModalTypeEnum.RituelParameters);
      refetch();
    }
  }, [isRitualParametersCorrect]);

  const modalAlredyOpen = useRef(false);

  useEffect(() => {
    if (data && !modalAlredyOpen.current) {
      if (!data.canSeePublicProblems) {
        openModal(ModalTypeEnum.Message, {
          title: "Ce rituel de problèmes s'appuie sur la banque de problèmes collaborative.",
          content:
            "Vous utilisez cet outil et vous l'appréciez ? Merci de contribuer à au moins trois problèmes afin de l'enrichir et participer à ce commun numérique. Après validation de vos problèmes par l'administrateur, cette fenêtre disparaîtra.",
          labelButton: "Contribuer",
          pathButton: Routes.CONTRIBUTE,
        });
      }
      modalAlredyOpen.current = true;

      setTextSize(40 - 8 * (data.problems.length - 1));
    }
  }, [data]);

  const todayDate = new Date().toLocaleDateString("fr-FR", { weekday: "long", year: "numeric", month: "long", day: "numeric" });

  return (
    <div
      style={{
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        position: "absolute",
        contain: "layout size style",
        overflow: "hidden",
        zIndex: 0,
      }}
      className="d-flex flex-column w-100 h-100"
    >
      <div className="w-100 text-center fw-bold mx-auto mb-2 z-1 bg-white" style={{ marginTop: "2vh", maxWidth: "1000px", boxShadow: "0px 10px 8px white" }}>
        <div className="d-flex justify-content-between bg-secondary rounded-4 p-3 mx-auto text-center" style={{ fontSize: "calc(14px + 0.5vh + 0.5vw)" }}>
          <Link to={Routes.HOME} className="align-self-center" aria-label="M@ths en-vie">
            <FontAwesomeIcon icon={faHouse} size="xl" />
          </Link>
          <p>
            Le problème du jour{" "}
            <span
              className="bg-black rounded-3 text-white"
              style={{ marginLeft: "2vw", padding: "calc(0.2vw + 0.5vh)" }}
              dangerouslySetInnerHTML={sanitize(todayDate.replace(/ /g, "&nbsp;").charAt(0).toUpperCase() + todayDate.replace(/ /g, "&nbsp;").slice(1))}
            ></span>
          </p>
          <Link to={Routes.HELP_RITUEL} className="align-self-center" aria-label="M@ths en-vie">
            <FontAwesomeIcon icon={faInfoCircle} size="xl" />
          </Link>
        </div>
      </div>
      <div className={"text-center w-100 mx-auto mb-5" + (showProblems ? " overflow-auto" : "")} style={{ maxWidth: "1000px" }}>
        {showProblems ? (
          isLoading ? (
            <CircularProgress style={{ marginTop: "calc(40vh - 66px)" }} />
          ) : isError ? (
            <Alert className="fs-4" style={{ marginTop: "calc(40vh - 66px)" }} variant={error.variant}>
              {error.message}
            </Alert>
          ) : (
            data?.problems.map((problem, index) => (
              <div key={problem.id}>
                <div style={{ margin: "calc(1.5vh - 2px) 0 0 0" }}>
                  {showNivel !== null && (
                    <div className="float-start badge text-bg-secondary text-black fs-6 rounded-bottom-0">{showNivel ? problem.nivel.toUpperCase() : " * ".repeat(index + 1)}</div>
                  )}
                  {showType && (
                    <div className="float-end">
                      <span className="badge text-bg-secondary text-black fs-6 rounded-bottom-0">{TextProblemTypeEnum[problem.type]}</span>
                      {getOperators(TextProblemTypeEnum[problem.type]) && (
                        <span className="badge text-bg-secondary text-black ms-2 fs-6 rounded-bottom-0">{getOperators(TextProblemTypeEnum[problem.type])}</span>
                      )}
                    </div>
                  )}
                  <div style={{ clear: "both" }}></div>
                </div>
                <div
                  className="text-white bg-primary rounded-bottom-4 p-2 text-break w-100 position-relative"
                  style={{ margin: "0 0 calc(1.5vh - 2px) 0", borderTopLeftRadius: showNivel !== null ? 0 : "1rem", borderTopRightRadius: showType ? 0 : "1rem" }}
                >
                  <button className="btn float-end p-0" style={{ marginTop: "-3px" }} onClick={() => refetchRituelProblem.mutate({ nivel: problem.nivel })}>
                    <FontAwesomeIcon className="text-white" icon={faRotateRight} size="2x" spin={refetchRituelProblem.isPending} />
                  </button>
                  <span style={{ fontSize: textSize }} dangerouslySetInnerHTML={sanitize(addBoldToQuestion(problem.statement))}></span>
                </div>
              </div>
            ))
          )
        ) : (
          <button
            className="btn btn-primary rounded-4 px-md-5"
            style={{ marginTop: "calc(25vh - 56px)" }}
            onClick={() => {
              if (isRitualParametersCorrect) {
                setShowProblems(true);
              } else {
                openModal(ModalTypeEnum.RituelParameters, { setParameters: setRituelParameters });
              }
            }}
          >
            <div className="d-flex justify-content-center" style={{ height: "20vh", paddingTop: "4vh" }}>
              <div style={{ marginRight: "6vh" }}>
                <Dice />
              </div>
              <div style={{ marginLeft: "6vh" }}>
                <Dice rotateNum={2} />
              </div>
            </div>
            <p className="fs-5">Cliquez sur les dés pour afficher le problème du jour.</p>
          </button>
        )}
      </div>
      {!showProblems && (
        <div className="w-100 text-center">
          <img alt="Logo Maths en-vie" src="img/logo_small.webp" style={{ width: "calc(15vh + 1vw)", marginTop: "calc(10vh - 80px)" }} />
        </div>
      )}
      <div className="w-100 text-center fw-bold mx-auto fixed-bottom bg-white" style={{ marginBottom: "2vh", maxWidth: "1000px", boxShadow: "0px -7px 8px white" }}>
        <div className="bg-secondary rounded-3 p-1 mx-auto text-center d-flex justify-content-around">
          <div>
            <input
              className="me-2"
              type="checkbox"
              id="showNivel"
              name="scales"
              checked={showNivel === true}
              onChange={() => setShowNivel(showNivel === false ? true : showNivel === null ? true : null)}
            />
            <label htmlFor="showNivel">
              <span>Niveau</span>
            </label>
          </div>
          <div>
            <input
              className="me-2"
              type="checkbox"
              id="showDifficulty"
              name="scales"
              checked={showNivel === false}
              onChange={() => setShowNivel(showNivel ? false : showNivel === null ? false : null)}
            />
            <label htmlFor="showDifficulty">
              <span className="d-none d-md-block">Difficulté</span>
              <span className="d-md-none">***</span>
            </label>
          </div>
          <div>
            <input className="me-2" type="checkbox" id="showType" name="scales" checked={showType} onChange={() => setShowType(!showType)} />
            <label htmlFor="showType">
              <span className="d-none d-md-block">Type de problème</span>
              <span className="d-md-none">Type</span>
            </label>
          </div>
          <div>
            <button className="btn btn-primary btn-sm py-0 px-1 mx-1" onClick={() => setTextSize((oldSize) => (oldSize -= 2))}>
              <FontAwesomeIcon icon={faMinus} />
            </button>
            <button className="btn btn-primary btn-sm py-0 px-1 mx-1" onClick={() => setTextSize((oldSize) => (oldSize += 2))}>
              <FontAwesomeIcon icon={faPlus} />
            </button>
            <span className="ms-2">Taille du texte</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Rituel;
