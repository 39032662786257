import React from "react";
import ReactDOM from "react-dom/client";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Link, RouterProvider, createBrowserRouter, isRouteErrorResponse, useRouteError } from "react-router-dom";

import "bootstrap/dist/js/bootstrap.min.js";
import "./styles/app-1.0.0.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { type ApiError } from "./hooks/useAPI/apis";
import { MutationData } from "./types";
import { BaseLayout, FakeProtectedRoute, LayoutPage, ProtectedRoute } from "./components";
import { Cgu, Contact, Credits, HelpRituel, Home, LegalNotices, Login, PasswordReset, PasswordResetRequest, Privacy, Register, ResendVerifyEmail, Rituel, UnsubscribeEmails } from "./pages";
import { ProfilLayout, ProfilParameters } from "./pages/Profil";
import { Routes } from "./routes";
import { Helmet, HelmetProvider } from "react-helmet-async";

declare module "@tanstack/react-query" {
  interface Register {
    defaultError: ApiError;
    mutationMeta: MutationData;
  }
}

const router = createBrowserRouter(
  [
    {
      element: <BaseLayout />,
      errorElement: <ErrorBoundary />,
      children: [
        {
          element: <LayoutPage scrollable />,
          children: [
            {
              path: Routes.LOGIN,
              element: <Login />,
            },
            {
              path: Routes.REGISTER,
              element: <Register />,
            },
            {
              path: Routes.UNSUBSCRIBE_EMAILS,
              element: <UnsubscribeEmails />,
            },
            {
              path: Routes.RESEND_VERIFY_EMAIL,
              element: <ResendVerifyEmail />,
            },
            {
              path: Routes.PASSWORD_RESET_REQUEST,
              element: <PasswordResetRequest />,
            },
            {
              path: Routes.PASSWORD_RESET,
              element: <PasswordReset />,
            },
            {
              path: Routes.HELP_RITUEL,
              element: <HelpRituel />,
            },
            {
              path: Routes.PROFIL,
              element: (
                <ProtectedRoute>
                  <ProfilLayout />
                </ProtectedRoute>
              ),
              children: [
                {
                  path: Routes.PROFIL_PARAMETERS,
                  element: <ProfilParameters />,
                },
              ],
            },
            {
              path: Routes.LEGAL_NOTICES,
              element: <LegalNotices />,
            },
            {
              path: Routes.PRIVACY,
              element: <Privacy />,
            },
            {
              path: Routes.CGU,
              element: <Cgu />,
            },
            {
              path: Routes.CONTACT,
              element: <Contact />,
            },
            {
              path: Routes.CREDITS,
              element: <Credits />,
            },
          ],
        },
        {
          element: <LayoutPage scrollable={false} scrollableOnMobile />,
          children: [
            {
              path: Routes.HOME,
              element: <Home />,
            },
          ],
        },
        {
          path: Routes.RITUEL,
          element: (
            <FakeProtectedRoute>
              <Rituel />
            </FakeProtectedRoute>
          ),
        },
      ],
    },
  ],
  { basename: process.env.REACT_APP_BASENAME_ROUTER }
);

function ErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return (
        <div className="mx-auto text-center" style={{ maxWidth: 960, marginTop: "50vh", transform: "translateY(-50%)" }}>
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
          <img src="img/logo_small.webp" alt="M@ths en-vie" width="180" height="180" />
          <h1>Erreur 404 :(</h1>
          <p className="mt-3 lead fw-bold">La page que vous cherchez n'existe plus ou n'as jamais existé.</p>
          <Link className="btn btn-ternary mt-3" to={Routes.HOME}>
            Retourner à la page d'acceuil
          </Link>
        </div>
      );
    }
  }

  return (
    <div className="mx-auto text-center" style={{ maxWidth: 960, marginTop: "50vh", transform: "translateY(-50%)" }}>
      <img src="img/logo_small.webp" alt="M@ths en-vie" width="180" height="180" />
      <h1>Erreur :(</h1>
      <p className="mt-3 lead fw-bold">Une erreur est survenue.</p>
      <Link className="btn btn-ternary mt-3" to={Routes.HOME}>
        Retourner à la page d'acceuil
      </Link>
    </div>
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      networkMode: !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? "always" : "online",
    },
    mutations: {
      networkMode: !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? "always" : "online",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ReactQueryDevtools />
      </QueryClientProvider>
    </HelmetProvider>
  </React.StrictMode>
);
