import { Controller, FieldError, FieldValues, Path, PathValue, RegisterOptions, useForm, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { Alert, CloseButton, Modal } from "react-bootstrap";
import Select, { GroupBase, Options } from "react-select";
import { TextProblemTypeEnum, SchoolNivelEnum, getOperators } from "../../utils";
import { api, ApiError } from "../../hooks";
import { ModalPropsDefault, MutationData, Period, Programmation } from "../../types";
import { useMutation } from "@tanstack/react-query";
import { FormButtonLoading } from "../";
import { useAuth } from "../../contexts/auth";
import { useEffect, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThanEqual, faQuoteLeft, faRoute, faTrash } from "@fortawesome/free-solid-svg-icons";

type FormValues = {
  f1: (keyof typeof TextProblemTypeEnum)[];
  f2: number;
  f3: number;
  f4: number;
  f5: boolean;
  f2Enable: boolean;
  f3Enable: boolean;
  f4Enable: boolean;
};

const options: Options<GroupBase<{ value: keyof typeof TextProblemTypeEnum; label: string }>> = [
  {
    label: "Parties-tout",
    options: [
      { value: "rdt", label: TextProblemTypeEnum.rdt + getOperators(TextProblemTypeEnum.rdt, true) },
      { value: "rdp", label: TextProblemTypeEnum.rdp + getOperators(TextProblemTypeEnum.rdp, true) },
      { value: "rdtpr", label: TextProblemTypeEnum.rdtpr + getOperators(TextProblemTypeEnum.rdtpr, true) },
      { value: "rvp", label: TextProblemTypeEnum.rvp + getOperators(TextProblemTypeEnum.rvp, true) },
      { value: "rnp", label: TextProblemTypeEnum.rnp + getOperators(TextProblemTypeEnum.rnp, true) },
    ],
  },
  {
    label: "Transformation",
    options: [
      { value: "refa", label: TextProblemTypeEnum.refa + getOperators(TextProblemTypeEnum.refa, true) },
      { value: "reia", label: TextProblemTypeEnum.reia + getOperators(TextProblemTypeEnum.reia, true) },
      { value: "rea", label: TextProblemTypeEnum.rea + getOperators(TextProblemTypeEnum.rea, true) },
      { value: "refm", label: TextProblemTypeEnum.refm + getOperators(TextProblemTypeEnum.refm, true) },
      { value: "reim", label: TextProblemTypeEnum.reim + getOperators(TextProblemTypeEnum.reim, true) },
      { value: "rem", label: TextProblemTypeEnum.rem + getOperators(TextProblemTypeEnum.rem, true) },
    ],
  },
  {
    label: "Comparaison",
    options: [
      { value: "rpqa", label: TextProblemTypeEnum.rpqa + getOperators(TextProblemTypeEnum.rpqa, true) },
      { value: "rgqa", label: TextProblemTypeEnum.rgqa + getOperators(TextProblemTypeEnum.rgqa, true) },
      { value: "re", label: TextProblemTypeEnum.re + getOperators(TextProblemTypeEnum.re, true) },
      { value: "rpqm", label: TextProblemTypeEnum.rpqm + getOperators(TextProblemTypeEnum.rpqm, true) },
      { value: "rgqm", label: TextProblemTypeEnum.rgqm + getOperators(TextProblemTypeEnum.rgqm, true) },
      { value: "rdr", label: TextProblemTypeEnum.rdr + getOperators(TextProblemTypeEnum.rdr, true) },
    ],
  },
  {
    label: "Problèmes à étapes",
    options: [
      { value: "add", label: "Additif" },
      { value: "mult", label: "Multiplicatif" },
      { value: "mixed", label: "Mixte" },
    ],
  },
  {
    label: "Special",
    options: [
      { value: "frac", label: TextProblemTypeEnum.frac },
      { value: "propor", label: TextProblemTypeEnum.propor },
      { value: "cart", label: TextProblemTypeEnum.cart },
    ],
  },
];

type EditProgrammationModalProps = {
  nivel: SchoolNivelEnum;
  period: Period;
};

function EditProgrammationModal({ show, closeModal, nivel, period }: ModalPropsDefault & EditProgrammationModalProps) {
  const { setProgrammation, user } = useAuth();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    setError,
    watch,
    control,
    formState: { errors, isLoading },
  } = useForm({ defaultValues: async () => await defaultValues });

  const containStepType = (types: (keyof typeof TextProblemTypeEnum)[]) => {
    const stepTypes: (keyof typeof TextProblemTypeEnum)[] = ["add", "mult", "mixed"];

    return stepTypes.some((o) => types.includes(o));
  };

  const defaultValues = useMemo(
    () =>
      new Promise<FormValues>((resolve) => {
        let filters = user?.programmation?.[nivel]?.[period];
        if (!filters && !Number.isInteger(period)) {
          filters = user?.programmation?.[nivel]?.[Math.floor(period) as Period];
        }

        resolve({
          f1: filters?.f1 ?? [],
          f2: filters?.f2 ?? 100_000_000_000,
          f3: filters?.f3 ?? 500,
          f4: filters?.f4 ?? 10,
          f5: filters?.f5 ?? true,
          f2Enable: !!filters?.f2,
          f3Enable: !!filters?.f3,
          f4Enable: !!filters?.f4 && containStepType(filters?.f1 ?? []),
        });
      }),
    [user?.programmation, nivel, period]
  );

  const editProgrammation = useMutation<MutationData & { newProgrammation: Programmation }, ApiError<FormValues>, FormValues>({
    mutationFn: (data) => {
      return api.programmation.update(nivel, period, data);
    },
    onSuccess: (data) => {
      setProgrammation(data.newProgrammation);

      setTimeout(() => {
        closeModal();
      }, 1000);
    },
    onError: (data) => {
      data.formErrors.forEach(({ name, message }) => {
        setError(name, { message: message });
      });
    },
  });

  useEffect(() => {
    defaultValues.then((data) => {
      reset(data);
    });
  }, [defaultValues, reset]);

  const f1 = watch("f1");

  useEffect(() => {
    if (f1 && !containStepType(f1) && f1.length !== 0) {
      setValue("f4Enable", false);
    }
  }, [f1, setValue]);

  const onSubmit = (data: FormValues) => {
    editProgrammation.mutate(data);
  };

  return (
    <Modal show={show} onHide={closeModal} centered backdrop="static">
      <Modal.Header>
        Niveau : <span className="text-uppercase ms-1 me-3 fw-bold">{nivel}</span>Période n° <span className="text-uppercase mx-1 fw-bold">{Math.floor(period)}</span>(
        {Number.isInteger(period) ? "1 ère" : "2 ème"} moitié)
        <CloseButton variant="white" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div>
            {editProgrammation.isError && editProgrammation.error.formErrors.length === 0 && <Alert variant={editProgrammation.error.variant}>{editProgrammation.error.message}</Alert>}
            {editProgrammation.isSuccess && <Alert variant="success">{editProgrammation.data.message}</Alert>}
            {errors.root && <Alert variant="danger">{errors.root.message}</Alert>}
          </div>
          <div className="mb-3 d-flex" style={{ textAlign: "left" }}>
            {!isLoading && (
              <Controller
                control={control}
                name="f1"
                defaultValue={getValues("f1")}
                render={({ field: { onChange, value, name, ref } }) => (
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    className="w-100"
                    styles={{
                      control: (styles) => ({
                        ...styles,
                        borderColor: errors.f1 ? "#dc3546" : styles.borderColor,
                        boxShadow: errors.f1 ? "0 0 0 #dc3546" : styles.boxShadow,
                        ":hover": {
                          borderColor: errors.f1 ? "#dc3546" : styles[":hover"]?.borderColor,
                        },
                      }),
                      placeholder: (styles) => ({
                        ...styles,
                        fontWeight: 900,
                        color: "black",
                      }),
                    }}
                    onChange={(val) => onChange(val.map((c) => c.value))}
                    value={([] as any[]).concat(...options.map((c) => c.options.filter((c) => value.includes(c.value))))}
                    ref={ref}
                    name={name}
                    noOptionsMessage={() => "Aucun résultat trouvé."}
                    options={options}
                    placeholder="Tous les types de problèmes"
                  ></Select>
                )}
              />
            )}

            {errors.f1 && <div className="invalid-feedback d-block">{errors.f1.message}</div>}
          </div>
          <div className="d-flex">
            <input className="form-check-input align-self-center me-3 mt-1" type="checkbox" {...register("f2Enable")} />
            <InputRange
              label="Taille maximale des données"
              name="f2"
              error={errors.f2}
              setValue={setValue}
              defaultErrors={{}}
              min={5}
              max={100_000_000_000}
              register={register}
              disabled={!watch("f2Enable")}
            />
            <FontAwesomeIcon className="rounded-5 p-2 text-white align-self-center ms-2 mt-2" style={{ backgroundColor: "#008000" }} icon={faLessThanEqual} />
          </div>
          <div className="d-flex">
            <input className="form-check-input align-self-center me-3 mt-1" type="checkbox" {...register("f3Enable")} />
            <InputRange
              label="Nombre de caractères maximum dans l'énoncé"
              name="f3"
              error={errors.f3}
              setValue={setValue}
              defaultErrors={{}}
              min={150}
              max={500}
              register={register}
              disabled={!watch("f3Enable")}
            />
            <FontAwesomeIcon className="rounded-5 p-2 text-white align-self-center ms-2 mt-2" style={{ backgroundColor: "#993366" }} icon={faQuoteLeft} />
          </div>
          <div className="d-flex mb-3">
            <input className="form-check-input align-self-center me-3 mt-1" type="checkbox" {...register("f4Enable")} disabled={f1 && !containStepType(f1) && f1.length !== 0} />
            <InputRange
              label="Nombre maximal de données dans un problème à étapes"
              name="f4"
              error={errors.f4}
              setValue={setValue}
              defaultErrors={{}}
              min={3}
              max={10}
              register={register}
              disabled={!watch("f4Enable")}
            />
            <FontAwesomeIcon className="rounded-5 py-2 px-2 text-white align-self-center ms-2 mt-2" style={{ backgroundColor: "#9E29FF" }} icon={faRoute} />
          </div>
          <div className="d-flex justify-content-between mb-3">
            <div>
              <input id="filters-f5" className="form-check-input me-3 mt-1" type="checkbox" {...register("f5")} />
              <label htmlFor="filters-f5">Présence de nombre décimaux</label>
            </div>
            <div className="rounded-5 text-center text-white fs-2" style={{ backgroundColor: "#E06034", width: 32, height: 32, lineHeight: "8px" }}>
              ,
            </div>
          </div>
          <button
            className="btn btn-sm btn-danger mb-2"
            type="button"
            onClick={() => {
              setValue("f1", []);
              setValue("f2Enable", false);
              setValue("f3Enable", false);
              setValue("f4Enable", false);
              setValue("f5", true);
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
          <span className="ms-2">Vider la sélection</span>
          <FormButtonLoading isPending={editProgrammation.isPending} label="Modifier" className="btn btn-primary float-end" />
        </form>
      </Modal.Body>
    </Modal>
  );
}

type InputRangeProps<T extends FieldValues, K extends Path<T>> = {
  name: K;
  register: UseFormRegister<T>;
  setValue: UseFormSetValue<T>;
  label: string;
  error?: FieldError;
  defaultErrors?: RegisterOptions<T, K>;
  disabled: boolean;
  min: number;
  max: number;
};

function InputRange<T extends FieldValues, K extends Path<T>>({ name, register, setValue, label, error, defaultErrors, disabled, min, max }: Readonly<InputRangeProps<T, K>>) {
  return (
    <div className="col mb-3">
      <label style={{ fontSize: 15 }} htmlFor={`filter-${name}`}>
        {label}
      </label>
      <div className="input-group">
        <input
          type="number"
          className={"form-control" + (error ? " is-invalid" : "")}
          id={`filter-${name}`}
          {...register(name, {
            onBlur(e) {
              let newValue = Number.parseInt(e.target.value);
              if (isNaN(newValue)) {
                newValue = 0;
              }
              setValue(name, Math.min(Math.max(newValue, min), max) as PathValue<T, K>);
            },
            ...defaultErrors,
          })}
          disabled={disabled}
          min={min}
          max={max}
        />
        <span className="input-group-text" id="basic-addon2">
          {min} - {max}
        </span>
      </div>
      {error && <div className="invalid-feedback d-block">{error.message}</div>}
    </div>
  );
}

export default EditProgrammationModal;
