import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Footer, Header } from ".";
import { useEffect, useState } from "react";

type LayoutPageProps = {
  scrollable: boolean;
  scrollableOnMobile?: boolean;
};

const headerHeight = 50;

const LayoutPage = ({ scrollable, scrollableOnMobile = false }: LayoutPageProps) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    windowResized();
    window.addEventListener("resize", windowResized, false);

    return () => {
      window.removeEventListener("resize", windowResized, false);
    };
  }, []);

  const windowResized = () => {
    setIsMobile(window.innerWidth < 992);
  };

  if (scrollable || (scrollableOnMobile && isMobile)) {
    return (
      <>
        <Header height={headerHeight} />
        <div className="container-fluid" style={{ marginTop: headerHeight }}>
          <div style={{ padding: "20px 0px" }}>
            <Outlet />
          </div>
        </div>
        <ToastContainer autoClose={5000} position="bottom-right" hideProgressBar draggable={false} limit={2} />
        <Footer onlyLinks={scrollableOnMobile} />
      </>
    );
  } else {
    return (
      <div
        style={{
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          display: "flex",
          position: "absolute",
          flexDirection: "column",
          justifyContent: "space-between",
          contain: "layout size style",
          overflow: "hidden",
          zIndex: 0,
        }}
      >
        <Header height={headerHeight} />
        <div className="overflow-hidden" style={{ height: "calc(100vh - " + headerHeight + "px)", marginTop: headerHeight }}>
          <Outlet />
        </div>
        <ToastContainer autoClose={5000} position="bottom-right" hideProgressBar draggable={false} limit={2} />
        <Footer onlyLinks />
      </div>
    );
  }
};

export default LayoutPage;
