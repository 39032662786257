import { useForm } from "react-hook-form";
import { api, ApiError } from "../hooks";
import { useMutation } from "@tanstack/react-query";
import { DefaultErrorsType, MutationData } from "../types";
import { Alert } from "react-bootstrap";
import { Checkbox, FormButtonLoading } from "../components";
import { useEffect } from "react";
import { useAuth } from "../contexts/auth";

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  subject: string;
  content: string;
  demandType: "pedagogique" | "technique" | null;
};

const defaultValues: FormValues = {
  firstName: "",
  lastName: "",
  email: "",
  subject: "",
  content: "",
  demandType: null,
};

const defaultErrors: DefaultErrorsType<FormValues> = {
  firstName: {
    required: "Veuillez saisir votre prénom.",
    maxLength: { value: 32, message: "Votre prénom doit contenir moins de 32 caratères." },
  },
  lastName: {
    required: "Veuillez saisir votre nom.",
    maxLength: { value: 32, message: "Votre nom doit contenir moins de 32 caratères." },
  },
  email: {
    required: "Veuillez saisir votre email.",
    maxLength: { value: 128, message: "Votre email doit contenir moins de 128 caratères." },
  },
  subject: {
    required: "Veuillez saisir le sujet de votre demande.",
    maxLength: { value: 128, message: "Le sujet ne peut pas contenir plus de 128 caractères." },
  },
  content: {
    required: "Veuillez saisir le contenu de votre demande.",
    maxLength: { value: 2000, message: "Le contenu ne peut pas contenir plus de 2000 caractères." },
  },
  demandType: {
    required: "Veuillez saisir le type de votre demande.",
  },
};

function Contact() {
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues });

  const contactMutation = useMutation<MutationData, ApiError<FormValues>, FormValues>({
    mutationFn: (emailData) => {
      return api.user.contact(emailData);
    },
    onSuccess: () => {
      reset();
    },
    onError: (data) => {
      data.formErrors.forEach(({ name, message }) => {
        setError(name, { message: message });
      });
    },
  });

  useEffect(() => {
    if (user) {
      setValue("email", user.email);
    }
  }, [user, setValue]);

  const onSubmit = (data: FormValues) => {
    contactMutation.mutate(data);
  };

  return (
    <div className="my-0 mx-auto" style={{ maxWidth: 960 }}>
      <h1 className="text-center mb-5">Nous contacter</h1>

      {contactMutation.isError && contactMutation.error.formErrors.length === 0 && <Alert variant={contactMutation.error.variant}>{contactMutation.error.message}</Alert>}
      {contactMutation.isSuccess && <Alert variant="success">{contactMutation.data.message}</Alert>}

      {errors.root && <Alert variant="danger">{errors.root.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="mb-3 text-center">
          <div className="text-center">
            <Checkbox label="Question pédagogique" name="demandType" value="pedagogique" register={register} error={errors.demandType} defaultErrors={defaultErrors.demandType} />
            <Checkbox label="Question technique" name="demandType" value="technique" register={register} error={errors.demandType} defaultErrors={defaultErrors.demandType} />
          </div>
          {errors.demandType && <div className="invalid-feedback d-block">{errors.demandType.message}</div>}
        </div>
        <div className="mb-3 row">
          <div className="col">
            <label htmlFor="form-firstName" className="form-label">
              Prénom
            </label>
            <input type="text" id="form-firstName" className={"form-control" + (errors.firstName ? " is-invalid" : "")} {...register("firstName", defaultErrors.firstName)} autoFocus />
            {errors.firstName && <div className="invalid-feedback d-block">{errors.firstName.message}</div>}
          </div>
          <div className="col">
            <label htmlFor="form-lastName" className="form-label">
              Nom
            </label>
            <input id="form-lastName" type="text" className={"form-control" + (errors.lastName ? " is-invalid" : "")} {...register("lastName", defaultErrors.lastName)} />
            {errors.lastName && <div className="invalid-feedback d-block">{errors.lastName.message}</div>}
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="form-email" className="form-label">
            Email
          </label>
          <input id="form-email" type="email" className={"form-control" + (errors.email ? " is-invalid" : "")} {...register("email", defaultErrors.email)} />
          {errors.email && <div className="invalid-feedback d-block">{errors.email.message}</div>}
        </div>
        <div className="mb-3">
          <label htmlFor="form-subject" className="form-label">
            Sujet
          </label>
          <input type="text" className={"form-control" + (errors.subject ? " is-invalid" : "")} id="form-subject" {...register("subject", defaultErrors.subject)} />
          {errors.subject && <div className="invalid-feedback d-block">{errors.subject.message}</div>}
        </div>
        <div className="mb-3">
          <label htmlFor="mail-content" className="form-label">
            Message
          </label>
          <textarea
            className={"form-control" + (errors.subject ? " is-invalid" : "")}
            id="mail-content"
            {...register("content", defaultErrors.content)}
            style={{ height: 150 }}
            placeholder="Rédigez votre message"
            required
          ></textarea>
          <div className="form-text">2000 caractères maximum</div>
          {errors.content && <div className="invalid-feedback d-block">{errors.content.message}</div>}
        </div>
        <FormButtonLoading label="Envoyer" type="submit" isPending={contactMutation.isPending} className="btn btn-primary" />
      </form>
    </div>
  );
}

export default Contact;
