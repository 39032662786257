import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { Link, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { UserData, useAuth } from "../contexts/auth";
import { api, ApiError } from "../hooks";
import { useForm } from "react-hook-form";
import { FormButtonLoading } from "../components";
import { Routes } from "../routes";
import { Alert } from "react-bootstrap";

type FormValues = {
  identifier: string;
  password: string;
  _remember_me: boolean;
};

const defaultValues: FormValues = {
  identifier: "",
  password: "",
  _remember_me: true,
};

function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues });

  const [searchParams] = useSearchParams();

  const { user, login, logout } = useAuth();

  const loginMutation = useMutation<UserData, ApiError, FormValues>({
    mutationFn: (loginData) => {
      return api.user.login(loginData);
    },
    onSuccess: (data) => {
      login(data, searchParams.get("redirect"));
    },
  });

  const onSubmit = (data: FormValues) => {
    loginMutation.mutate(data);
  };

  return (
    <div className="form-template mx-auto">
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="label-form">
          <h1 className="h3 text-center">Se connecter</h1>
        </div>
        <div className="alert-form">
          {loginMutation.isError && <Alert variant={loginMutation.error.variant}>{loginMutation.error.message}</Alert>}
          {searchParams.has("message") && <Alert variant={searchParams.get("variant") ?? "info"}>{searchParams.get("message")}</Alert>}
          {user && (
            <div className="mb-3">
              Vous êtes connecté en tant que {user.email},{" "}
              <Link to={Routes.HOME} onClick={logout}>
                se déconnecter.
              </Link>
            </div>
          )}
        </div>

        <div className="body-form">
          <div className="alert alert-info alert-dismissible fade show" role="alert">
            <span className="text-decoration-underline fw-bold">Attention</span>, si vous n'avez pas encore créé de compte, cliquez ci-dessous sur "Je n'ai pas de compte".{/**/}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>

          <div className="mb-3">
            <div className="input-group">
              <span className="input-group-text">
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
              <div className="form-floating">
                <input
                  type="email"
                  {...register("identifier", { required: true })}
                  id="inputIdentifier"
                  className={"form-control" + (errors.identifier ? " is-invalid" : "")}
                  placeholder="Identifiant"
                  required
                  autoFocus
                />
                <label htmlFor="inputIdentifier" className="form-label">
                  Identifiant
                </label>
              </div>
              {errors.identifier && <div className="invalid-feedback d-block">Vous devez saisir votre identifiant</div>}
            </div>
            <div className="form-text">L'identifiant peut être l'email ou le pseudo</div>
          </div>

          <div className="input-group">
            <span className="input-group-text">
              <FontAwesomeIcon icon={faLock} />
            </span>
            <div className="form-floating">
              <input
                type="password"
                {...register("password", { required: true })}
                id="current-password"
                className={"form-control" + (errors.password ? " is-invalid" : "")}
                placeholder="Mot de passe"
                autoComplete="current-password"
                required
              />
              <label htmlFor="current-password" className="form-label">
                Mot de passe
              </label>
            </div>
            {errors.password && <div className="invalid-feedback d-block">Vous devez saisir votre mot de passe</div>}
          </div>
          <div className="text-end">
            <Link to={Routes.PASSWORD_RESET_REQUEST} className="link-primary text-decoration-none">
              Mot de passe oublié ?
            </Link>
          </div>

          <div className="mb-3 form-check checkbox">
            <input type="checkbox" {...register("_remember_me")} className="form-check-input" id="remember_me" />
            <label className="form-check-label" htmlFor="remember_me">
              Se souvenir de moi
            </label>
          </div>

          <div className="btn-form">
            <FormButtonLoading isPending={loginMutation.isPending} label="Se connecter" className="btn btn-primary" />
          </div>
          <div className="row mt-3">
            <div className="text-start col-6">
              <Link to={Routes.RESEND_VERIFY_EMAIL} className="link-primary text-decoration-none">
                Renvoyer l'email de confirmation
              </Link>
            </div>
            <div className="text-end col-6">
              <Link to={Routes.REGISTER} className="link-primary text-decoration-none">
                Je n'ai pas de compte <FontAwesomeIcon icon={faRightFromBracket} />
              </Link>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Login;
