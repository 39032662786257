import { ProfilAPI, ProgrammationAPI, RituelAPI, UserAPI } from "./apis";
import HomeAPI from "./apis/homeAPI";

const api = {
  home: new HomeAPI(),
  profil: new ProfilAPI(),
  programmation: new ProgrammationAPI(),
  rituel: new RituelAPI(),
  user: new UserAPI(),
};

export default api;
