import { OverlayTrigger, Tooltip as ReactToolTip } from "react-bootstrap";

type ToolTipProps = {
  id: string;
  value: string;
  disabled?: boolean;
};

function Tooltip({ children, id, value, disabled = false }: React.PropsWithChildren<ToolTipProps>) {
  return !disabled ? <OverlayTrigger overlay={<ReactToolTip id={id}>{value}</ReactToolTip>}>{children as any}</OverlayTrigger> : children;
}

export default Tooltip;
