/**
 * All available routes for the app
 */

const Routes = {
  HOME: "/",

  RITUEL: "/rituel",
  HELP_RITUEL: "/help/rituel",

  LOGIN: "/login",
  REGISTER: "/register",
  RESEND_VERIFY_EMAIL: "/resend-verify-email",
  PASSWORD_RESET_REQUEST: "/password-reset-request",
  PASSWORD_RESET: "/password-reset/:token",
  UNSUBSCRIBE_EMAILS: "/unsubscribe-emails",
  PROFIL: "/profil",
  PROFIL_PARAMETERS: "/profil/parameters",

  LEGAL_NOTICES: "/mentions-legales",
  PRIVACY: "/privacy",
  CGU: "/cgu",
  CONTACT: "/contact",
  CREDITS: "/credits",

  // Others url
  CONTRIBUTE: process.env.REACT_APP_BANQUE_URL + "/add/textProblem",
} as const;

const Titles = {
  RITUEL: "Le problème du jour - Rituel de problèmes",
  HELP_RITUEL: "Aide relative aux fonctionnalités du rituel de problèmes",

  LOGIN: "Se connecter - Les applications M@ths en-vie",
  REGISTER: "S'inscrire - Les applications M@ths en-vie",
  RESEND_VERIFY_EMAIL: "Renvoyer l'email de confirmation",
  UNSUBSCRIBE_EMAILS: "Désinscription des emails",
  PROFIL: "Profil",
  PROFIL_PARAMETERS: "Profil - Paramètres",

  LEGAL_NOTICES: "Mentions légales - Appenvie",
  PRIVACY: "Politique de confidentialité - Appenvie",
  CGU: "Conditions Générales d'Utilisation - Appenvie",
  CONTACT: "Nous contacter - Appenvie",
  CREDITS: "Crédit - Appenvie",
} as const;

const Descriptions = {
  RITUEL: "Chaque jour d'école, un nouveau problème pour chaque niveau.",

  LOGIN: "Se connecter pour accéder aux applications M@ths en-vie.",
  REGISTER: "S'inscrire pour accéder aux applications M@ths en-vie.",
} as const;

const NoIndex: (keyof typeof Routes)[] = ["RESEND_VERIFY_EMAIL", "PASSWORD_RESET_REQUEST", "PASSWORD_RESET", "UNSUBSCRIBE_EMAILS", "PROFIL", "PROFIL_PARAMETERS"];

export { Routes, Titles, Descriptions, NoIndex };
