import { FormButtonLoading } from "../components";
import { api, ApiError } from "../hooks";
import { useMutation } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { MutationData } from "../types";
import { Alert } from "react-bootstrap";
import { faCalendarCheck, faCalendarXmark } from "@fortawesome/free-solid-svg-icons";

function UnsubscribeEmails() {
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");
  const token = searchParams.get("token");

  const unsubscribe = useMutation<MutationData & { isUnsubscribe?: boolean }, ApiError, { id: number; token: string; reSubscribe?: boolean }>({
    mutationFn: ({ id, token, reSubscribe = false }) => {
      return api.user.unsubscribeEmails(id, token, reSubscribe);
    },
  });

  return (
    <div className="m-auto" style={{ maxWidth: 960 }}>
      {unsubscribe.isError && <Alert variant={unsubscribe.error.variant}>{unsubscribe.error.message}</Alert>}
      {unsubscribe.isSuccess && <Alert variant="success">{unsubscribe.data.message}</Alert>}
      {id === null || token === null ? (
        <Alert variant="warning">Lien invalide, si vous avez copiez le lien assurez-vous que l'url a été copiée en entier.</Alert>
      ) : (
        <div className="text-center">
          <p className="lead fw-bold mb-3">Souhaitez-vous réellement vous désinscrire de la lettre d'information de la banque M@ths en-vie ?</p>
          {unsubscribe.data?.isUnsubscribe === true ? (
            <FormButtonLoading
              className="btn btn-primary"
              icone={faCalendarCheck}
              isPending={unsubscribe.isPending}
              label="Se résinscire"
              onClick={() => unsubscribe.mutate({ id: Number.parseInt(id), token: token, reSubscribe: true })}
            />
          ) : (
            <FormButtonLoading
              className="btn btn-primary"
              icone={faCalendarXmark}
              isPending={unsubscribe.isPending}
              label="Se désinscire"
              onClick={() => unsubscribe.mutate({ id: Number.parseInt(id), token: token })}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default UnsubscribeEmails;
