import { SchoolZoneEnum } from "../utils";

const RituelKey = (schoolZone: SchoolZoneEnum, classNivel: string[]): [base: string, schoolZone: SchoolZoneEnum, classNivel: string[]] => ["rituel", schoolZone, classNivel];

const UserStatsKey: [base: string] = ["stats"];

export { UserStatsKey, RituelKey };

export type UserStatsData = {
  userRegisteredAmout: number;
  contributions: number;
  problemsResolvedAmouts: number;
  programmation: string;
  zone: string;
};
